.Section {
    padding: 2%;
    margin: 4%;
    background-color: white;
    -webkit-box-shadow: 0px 12px 16px -6px rgba(0,0,0,0.55); 
    box-shadow: 0px 12px 16px -6px rgba(0,0,0,0.55);
}

.Image {
    width: 100%;
    height: 100%;
    max-height: 80vh;
    object-fit: contain;
    
}

.carousel-control-next-icon, .carousel-control-prev-icon {
	 filter: invert(1) grayscale(50); 
}

.carousel-indicators {
    filter: invert(0.25)
}

.carousel-caption-container {
    opacity: 0.6;
    bottom: 10%;
    right: 10%;
    left: 10%;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.5rem;
}

h5 {
    font-size: 2rem;
}

.caption-title {
    color: black;
    font-family: 'Sacramento', cursive;
}

.hyperlink-container {
    display: flex;
    justify-content: space-evenly;
    width: 80%;
}

a {       
    color: rgb(78, 78, 78);
    font-size: 1rem;
}

a:hover {
    color: black;
}

@media screen and (max-width: 1920px) {
    h5 {
        font-size: 1.5rem;
    }

    a {
        font-size: 0.75rem;
    }
}
