.categoryToggle {
    transition: all 200ms;
    height: 150px;
    cursor: pointer;
    filter: saturate(0%);
    opacity: 60%;
}

.categoryChecked {
    filter: saturate(100%);
    opacity: 100%;
}

@media screen and (max-width: 600px) {
    .categoryToggle {
        height: 100px;
    }   
}