body {
    background-color: #fbf6f1;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

h1,
h2 {
    font-family: 'Dancing Script', cursive;
    font-family: 'Parisienne', cursive;
    font-family: 'Sacramento', cursive;
    font-size: 4rem;
}

#banner {
    height: 200px;
    background-image: url("../public/banner.jpg");
    background-size: 100%;
    padding-top: 68px;
}

#sewbanner {
    height: 200px;
    background-image: url("../public/sewing-banner.jpg");
    background-size: 100%;
    padding-top: 68px;
}

#bannerText {
    font-size: 4rem;
    color: #484848;
    display: flex;
    align-items: center;
    justify-content: center;
}

#banner-dropdown-container {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    padding: 0;
    display: flex;
}

#dropdown-basic-button {
    background-color: transparent;
    border: 0;
    font-family: 'Dancing Script', cursive;
    font-family: 'Parisienne', cursive;
    font-family: 'Sacramento', cursive;
    font-size: 4rem;
    color: #484848;
}

#dropdown-basic-button:hover {
    color: #000;
}

.dropdownMenu {
    display: flex;
    border-radius: 30px;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.5);
    font-family: 'Dancing Script', cursive;
    font-family: 'Parisienne', cursive;
    font-family: 'Sacramento', cursive;
    font-size: 4rem;
    position: absolute;
    top: 100%;
    z-index: 10;
}

.dropdownItem {
    width: 100%;
    height: 100%;
    border-radius: 30px;
    padding: 5px 10px 5px 10px;
    cursor: pointer;
}

.dropdownItem:hover,
.dropdownItem:focus {
    background-color: rgba(255, 255, 255, 0.5);
    color: #000;
}

.row {
    --bs-gutter-x: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&family=Inconsolata:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&family=Inconsolata:wght@300&family=Parisienne&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&family=Inconsolata:wght@300&family=Parisienne&family=Sacramento&display=swap');

/* #### Mobile Phones Portrait #### */
@media screen and (max-width: 600px) {
    #banner {
        height: 160px;
    }

    #bannerText,
    #dropdown-basic-button,
    .dropdownMenu {
        font-size: 3rem;
    }
}

/* 
@media screen and (min-width: 1200px) {

    h1,
    .h1,
    #dropdown-basic-button,
    .dropdownMenu {
        font-size: 2.5rem;
    }
} */